// import { useI18n } from 'vue-i18n'
// eslint-disable-next-line import/order
// import { useNuxtApp } from '#imports'

export function getCurrencySymbol(currency: string) {
  if (currency === 'eur') return '€'
  if (currency === 'usd') return '$'
  return '$'
}

export function getPricedNumber(price?: number, currency?: string) {
  const r = price ? price : 0
  if (!currency || currency === 'usd') return `${r} $`
  if (currency === 'eur') return `${r} €`
  return `${price ? price : 0}`
}

export function getSelectPriceRanges() {
  const { t } = useNuxtApp().$i18n // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables
  return [
    {
      title: t('All'),
      value: 'all',
      min: 0,
    },
    {
      title: 'FREE 0$',
      value: '0',
      min: 0,
      max: 0,
    },
    {
      title: `1 ${t('to')} 5$`,
      value: '1-5',
      min: 100,
      max: 500,
    },
    {
      title: `5 ${t('to')} 10$`,
      value: '5-10',
      min: 500,
      max: 1000,
    },
    {
      title: `10 ${t('to')} 15$`,
      value: '10-15',
      min: 1000,
      max: 1500,
    },
    {
      title: `15 ${t('to')} 20$`,
      value: '15-20',
      min: 1500,
      max: 2000,
    },
    {
      title: `> 20$`,
      value: '20+',
      min: 2000,
    },
  ]
}
